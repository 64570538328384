<template>
  <div>
    <div class="home">
      <div class="title">Welcome</div>
    </div>
    <div class="games">
      <Game v-for="game in games" :key="game.id" :game="game"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Game from '@/components/Game.vue'

export default {
  name: 'Home',
  components: {
    Game
  },
  data () {
    return {
      games: [
        {
          id: 0,
          name: 'Hit The Duck',
          image: 'shot_it.jpg',
          description: 'This is a shooter game',
          appStoreLink: ''
        },
        {
          id: 1,
          name: 'Galactos',
          image: 'galactos.png',
          description: 'Coming Soon !!!',
          appStoreLink: ''
        }
      ]
    }
  }
}
</script>
<style scoped>
.home {
  margin-bottom: 50px;
}
.title {
  font-size: 60px;
  margin-top: 10px;
  color: #e6814b;
}
.games {
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
@media screen and (max-width: 820px) {
  .games {
    flex-direction: column;
  }
}
</style>
