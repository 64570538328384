<template>
  <div>
    <Navigation />
    <router-view/>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation'

export default {
  components: {
    Navigation
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: 'Luckiest Guy', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@media screen and (max-width: 740px) {
  .bm-menu {
    background-color: #03455c !important;
  }
  .bm-burger-button {
    top: 15px !important;
  }
  .bm-burger-bars {
    background-color: #fff !important;
  }
  .bm-cross {
    background: #fff !important;
  }
}
</style>
