<template>
  <div id="nav">
    <Slide class="slide-menu">
      <router-link to="/" class="item">Home</router-link>
      <router-link to="/privacy" class="item">Privacy Policy</router-link>
      <router-link to="/about" class="item">About</router-link>
    </Slide>
    <div class="navigation">
      <router-link to="/" class="item">Home</router-link>
      <router-link to="/privacy" class="item">Privacy Policy</router-link>
      <router-link to="/about" class="item">About</router-link>
    </div>
  </div>
</template>
<script>
import { Slide } from 'vue3-burger-menu'
export default {
  components: {
    Slide
  }
}
</script>
<style lang="scss" scoped>
.navigation {
  display: block;
}
.slide-menu {
    display: none;
  }
.item {
  display: inline-block;
  background-color: orange;
  width: 170px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid rgb(255, 94, 0);
  text-align: center;
  margin-left: 10px;
  line-height: 40px;
  font-family: 'Luckiest Guy';
  &.router-link-exact-active {
    background-color: #e88f45;
  }
}
#nav {
  padding: 10px 0px;
  background-color: deepskyblue;
  a {
    text-decoration: none;
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #fff;
    }
  }
}
@media screen and (max-width: 740px) {
  .navigation {
    display: none;
  }
  .slide-menu {
    display: block;
  }
  #nav {
    height: 40px;
  }
  .item {
    font-size: 16px;
    display: block;
    width: 180px;
    height: 25px;
    margin-bottom: 10px;
    margin-left: 0px;
    line-height: 26px;
    font-weight: normal;
  }
}
</style>
