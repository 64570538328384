<template>
  <div>
    <div class="separator-big"></div>
    <div class="title">Privacy Policy</div>
    <div class="separator-small"></div>
    <div class="container">
      <span>Last Update: 27.10.2022</span>
    </div>
    <div class="container">
      <div class="separator-big"></div>
      <div class="sub-title">Information Collection</div>
      <div class="separator-small"></div>
      <div class="content">
        <div>
          <b
            >We do not collect any information that is related to you or the
            devices on which you have installed our games.</b
          >
        </div>
        We also do not integrate with any tirth party applications or advertising
        providers within our games. We are not using Notifications, Cookies or
        any other sort of tracking services.
        <div class="separator-big"></div>
        <p class="sub-title-bold">In-App Purchase Terms</p>
        <div class="separator-xs"></div>
        We use Apple’s billing software to process In App purchases from our Games
        made through the App Store. As a result, when making your purchase
        from our games through the App Store, you will be asked to
        provide personal information to Apple, such as your app store password (or Face ID) for billing purposes. This information is
        collected by Apple in order for them to validate the purchase.
        <b>It is neither collected nor used by us</b>.
        <div class="separator-big"></div>
        <p class="sub-title-bold">Data Retention</p>
        <div class="separator-xs"></div>
        <b>We do not retain any of your data as we do not collect any data from you</b>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.container {
  text-align: left;
  padding: 10px 30px;
}
.title {
  font-family: "Roboto Condensed";
  font-size: 50px;
  margin: 0;
}

.sub-title {
  font-family: "Roboto Condensed";
  font-size: 40px;
  margin: 0;
}

.sub-title-bold {
  font-family: "Roboto Condensed";
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}

.content {
  font-family: "Roboto Condensed";
  font-size: 22px;
  margin: 0;
}

.separator {
  &-big {
    height: 30px;
  }
  &-small {
    height: 18px;
  }
  &-xs {
    height: 13px;
  }
}
</style>
