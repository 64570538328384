<template>
  <div class="game">
    <img alt="game logo" :src="bgImage">
    <h1>{{ game.name }}</h1>
    <p>{{ game.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'Game',
  props: {
    game: Object
  },
  computed: {
    bgImage () {
      return require('../assets/' + this.game.image)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game {
  box-shadow: 5px 5px 6px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 400px;
  padding: 15px 0;
}
.game:hover {
  cursor: pointer;
  box-shadow: -5px -5px 6px 4px rgba(0, 0, 0, 0.15);
}
img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
@media screen and (min-width:421px) and (max-width: 820px) {
  .game {
    margin-bottom: 20px;
  }
}
@media screen and (max-width:420px) {
  .game {
    margin-bottom: 20px;
    width: 300px;
  }
  img {
    width: 250px;
    height: 250px;
  }
}
</style>
